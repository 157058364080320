import React from 'react';

function Footer() {
    return (

        <footer style={{
            backgroundColor: '#f2f2f2',
            padding: '20px',
            textAlign: 'center',
            // position: 'fixed',
            bottom: '0',
            left: '0',
            right: '0',
            borderTop: '1px solid #ccc',
            color: 'black',
        }}>

            <div>

                <p style={{ margin: '0' }}>
                    &copy; {new Date().getFullYear()} Abili UG. All rights reserved.
                    <br />
                    <small className="currency-abbreviation">Abili.de</small>
                </p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <a target="_blank" className="btn btn-primary" href="https://3bili.com/index.php?ask" style={{ fontSize: 'xx-small', margin: '0 5px' }}>
                        Contact
                    </a>
                    <a target="_blank" className="btn btn-primary" href="https://3bili.com/index.php?page=dsgvo" style={{ fontSize: 'xx-small', margin: '0 5px' }}>
                        DSGVO
                    </a>
                    <a target="_blank" className="btn btn-primary" href="https://3bili.com/index.php?page=impressum" style={{ fontSize: 'xx-small', margin: '0 5px' }}>
                        Impressum
                    </a>
                    <a target="_blank" className="btn btn-primary" href="https://3bili.com/agb.pdf" style={{ fontSize: 'xx-small', margin: '0 5px' }}>
                        AGB
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
