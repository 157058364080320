import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./NewsArticle.css";

const NewsArticle = () => {
    const { id } = useParams();
    const [newsArticle, setNewsArticle] = useState(null);

    useEffect(() => {
        const fetchNewsArticle = async () => {
            const response = await axios.get(`https://brmja.co/news/get_news_content.php?id=${id}`);
            setNewsArticle(response.data);
        };

        fetchNewsArticle();
    }, [id]);

    if (!newsArticle) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8" dir="rtl" style={{ textAlign: 'right' }}>
                    <h5 dir="rtl" style={{ textAlign: 'right' }} className="article-title" dangerouslySetInnerHTML={{ __html: newsArticle.title }} />
                    <div className="card" style={{ padding: '1rem' }}>
                        <img
                            src={`https://brmja.co/news/${newsArticle.featured_img}`}
                            alt={newsArticle.title}
                            className="card-img-top"
                        />
                        <div className="card-body">
                            <div className="card-text mb-2 text-muted"
                                dangerouslySetInnerHTML={{
                                    __html: newsArticle.content.replace(/<figure.*figure>/g, ''),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default NewsArticle;
