import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {Container} from "react-bootstrap";
import Head from './components/helpers/Head'; // Add this line
import Footer from './components/helpers/Footer';
import "./App.css";
import News from './components/news/News';
import CurChanger from './components/cur/CurChanger';
import NewsArticle from './components/news/NewsArticle'; // Add this line
import Auth from './components/sign/Auth';
import TopUp from './components/TopUp/TopUp';
import React,{ useEffect } from "react";
import getIpLocation from './components/helpers/getIpLocation';
const App = () => {

    useEffect(() => {
        const fetchIpLocation = async () => {
            const locationData = await getIpLocation();
            if (locationData) {
                localStorage.setItem('ipLocation', JSON.stringify(locationData));
            }
        };
        fetchIpLocation();
    }, []);


    return (
        <Router>
            <Container className="App">
                <Head />
                <Routes>
                    <Route path="/" element={<CurChanger />} />
                    <Route path="/cur" element={<CurChanger />} />
                    <Route path="/news" element={<News />} />
                    <Route path="/auth" element={<Auth />} />
                    <Route path="/topup" element={<TopUp />} />
                    <Route path="/news/:id" element={<NewsArticle />} />
                </Routes>
                <br />
                <Footer />
            </Container>
        </Router>
    );
};

export default App;
