import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Head = () => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    return (
        <Container fluid className="bg-light">
            <HelmetProvider>
                <Helmet>
                    <title>{t('title')}</title>
                    <meta property="og:title" content={t('title')} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.brmja.co" />
                    <meta property="og:description" content={t('description')} />
                    <meta property="og:image" content="https://www.brmja.co/flags/new-bg.jpg" />
                    <meta name="author" content="abili.de" />
                </Helmet>
            </HelmetProvider>

            <Navbar bg="light" expand="lg">
                <Navbar.Brand>
                    <Link to="/" className="nav-link">
                        {i18n.language === 'en' ? (
                            <button className="btn btn-link" onClick={() => changeLanguage('ar')}>
                                <img src="/flags/sa.png" width="24" height="24" alt="ar" />
                            </button>
                        ) : (
                            <button className="btn btn-link" onClick={() => changeLanguage('en')}>
                                <img src="/flags/us.png" width="24" height="24" alt="en" />
                            </button>
                        )}

                        {t('title')}
                    </Link>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Link to="/" className="nav-link">
                            {t('home')}
                        </Link>
                        {/*<Link to="/cur" className="nav-link">*/}
                        {/*    {t('CurChanger')}*/}
                        {/*</Link>*/}
                        <Link to="/news" className="nav-link">
                            {t('news')}
                        </Link>
                        {/*<Link to="/TopUp" className="nav-link">*/}
                        {/*    {t('TopUp')}*/}
                        {/*</Link>*/}
                        {/*<Link to="/auth" className="nav-link">*/}
                        {/*    {t('auth')}*/}
                        {/*</Link>*/}
                    </Nav>

                    <Nav className="ml-auto">
                        <a
                            href="https://3bili.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary ml-2"
                            style={{ fontSize: 'small' }}
                        >
                            {t('mobileRecharge')}
                        </a>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    );
};

export default Head;
