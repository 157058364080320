import axios from 'axios';

async function getIpLocation() {
    try {
        const response = await axios.get('https://brmja.co/api/get-ip-location.php');
        const data = response.data;
        return data;
    } catch (error) {
        console.error('Failed to fetch IP and location:', error);
        console.error('Error details:', error.response);
        return null;
    }
}


export default getIpLocation;