import React, { useState, useEffect } from 'react';
import {Button, Col, Container, Form, Row, Tab, Table, Tabs} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';


const locations = ["aleppo", "damascus", "alhasakah", "idlib"];

const CurrencyTable = ({data = [] }) => {
    const {t} = useTranslation();
    const [showAll, setShowAll] = useState(false);
    const [fromAmount, setFromAmount] = useState(0);
    const [fromCurrency, setFromCurrency] = useState("USD");
    const [toCurrency, setToCurrency] = useState("SAR");
    const [conversionType, setConversionType] = useState("ask");
    const [convertedAmount, setConvertedAmount] = useState(0);


    useEffect(() => {
        calculateConversion(fromAmount, fromCurrency, toCurrency, conversionType);
    }, [fromCurrency, toCurrency, conversionType, fromAmount]);

    const handleFromCurrencyChange = (e) => {
        setFromCurrency(e.target.value);
        calculateConversion(fromAmount, e.target.value, toCurrency, conversionType);
    };


    const handleConversionTypeChange = (e) => {
        setConversionType(e.target.value);
        calculateConversion();
    };

    const calculateConversion = (newValue, fromCurrency, toCurrency, conversionType) => {
        const fromCurrencyData = data.find((currency) => currency.name === fromCurrency);
        const toCurrencyData = data.find((currency) => currency.name === toCurrency);

        if (!fromCurrencyData || !toCurrencyData) {
            setConvertedAmount(0);
            return;
        }

        const fromRate = parseFloat(conversionType === 'ask' ? fromCurrencyData.ask : fromCurrencyData.bid);

        const result = newValue * fromRate;
        setConvertedAmount(result.toFixed(2));
    };

    const handleFromAmountChange = (e) => {
        const value = Math.max(0, parseFloat(e.target.value) || 0); // Ensure the value is non-negative
        setFromAmount(value);
    };


    return (
        <Row>
            <Col lg={6} className="mb-3">
                <Table bordered hover responsive className="rtl-table">
                    <thead>
                    <tr>
                        <th>{t("name")}</th>
                        <th>{t("ask")}</th>
                        <th>{t("bid")}</th>
                        <th>{t("change")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data
                        .slice(0, showAll ? data.length : 6)
                        .map((currency) => (
                            <tr key={currency.name}>
                                <td>
                                    <img src={`./flags/${currency.icon}`}
                                         alt={`${currency.name} flag`} className="flag-icon"/>{" "}
                                    {currency.ar_name}
                                    <small className="currency-abbreviation">({currency.name})</small>
                                </td>
                                <td className="ask-bid">{currency.ask}</td>
                                <td className="ask-bid">{currency.bid}</td>
                                <td className={currency.arrow === "1" ? "up" : "down"}>
                                    {currency.arrow === "1" ? "↑" : "↓"} {currency.change}
                                    <small className="currency-abbreviation">({currency.change_percentage}%)</small>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Button onClick={() => setShowAll(!showAll)} className="mt-3">
                    {showAll ? t("showLess") : t("showMore")}
                </Button>
            </Col>
            <Col lg={6} className="mb-3">
                <h2>{t("currencyConverter")}</h2>

                <Form>

                    {/* Conversion type */}
                    <Form.Group controlId="conversionType">
                        {/*<Form.Label>{t("conversionType")}</Form.Label>*/}
                        <Form.Check
                            inline
                            type="radio"
                            name="conversionType"
                            value="ask"
                            checked={conversionType === "ask"}
                            onChange={handleConversionTypeChange}
                            label={t("ask")}
                        />
                        <Form.Check
                            inline
                            type="radio"
                            name="conversionType"
                            value="bid"
                            checked={conversionType === "bid"}
                            onChange={handleConversionTypeChange}
                            label={t("bid")}
                        />
                        <hr />
                    </Form.Group>


                    {/* From */}
                    <Form.Group controlId="fromAmount">
                        <Row>
                            <Col xs={8}>
                                {/*<Form.Label>{t("from")}</Form.Label>*/}
                                <Form.Control
                                    type="tel"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    size="lg"
                                    value={fromAmount}
                                    onChange={handleFromAmountChange} // Add this line
                                    // onChange={(e) => setFromAmount(e.target.value)}
                                />
                            </Col>
                            <Col xs={4}>

                                {/*<Form.Label>&nbsp;</Form.Label>*/}
                                <Form.Control as="select" value={fromCurrency} onChange={handleFromCurrencyChange}>
                                    {data
                                        .slice(0, showAll ? data.length : 2)
                                        .filter((currency) => currency.name !== 'SAR')
                                        .map((currency) => (
                                            <option key={currency.name} value={currency.name}>
                                                {t(`currencies.${currency.name}.name`)}
                                            </option>
                                        ))}
                                </Form.Control>
                            </Col>
                            <Col xs={12}>
                                <Form.Label>=</Form.Label>
                            </Col>
                            <Col xs={12}>
                                <Form.Control
                                    type="tel"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    size="lg"
                                    value={convertedAmount}
                                    readOnly disabled
                                />
                            </Col>

                        </Row>
                    </Form.Group>
                </Form>


            </Col>

        </Row>
    );
};

const CurChanger = () => {
    const [data, setData] = useState({});
    const [activeTab, setActiveTab] = useState(locations[0]);
    const { t, i18n } = useTranslation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    const fetchCurrencyData = async (key) => {
        const api_key = "jgHtQWmXyLzAfpn";
        const url = `https://brmja.co/proxy.php?location=${key}&api_key=${api_key}`;
        try {
            const response = await axios.get(url);
            setData((prevData) => ({ ...prevData, [key]: response.data }));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        locations.forEach((location) => {
            fetchCurrencyData(location);
        });
    }, []);

    return (
        <Container className="App">
            <Tabs
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                className="mb-3 d-flex justify-content-center"
            >
                {locations.map((location) => (
                    <Tab
                        eventKey={location}
                        title={t(`locations.${location}`)}
                        key={location}
                    >
                        {data[location] && Array.isArray(data[location]) ? (
                            <CurrencyTable data={data[location]} />
                        ) : (
                            <p>Loading data...</p>
                        )}
                    </Tab>
                ))}
            </Tabs>
        </Container>
    );
};

export default CurChanger;
