import React from 'react';
import { Tab, Tabs, Container } from 'react-bootstrap';
import Syria from './syria';
import Turkey from './syria';


import './../tabs.css';

const TopUp = () => {
    return (
        <Container className="auth-container">
            {/*<h2 className="text-center">أختر التعبئة</h2>*/}
            <Tabs defaultActiveKey="syria" className="justify-content-center">
                <Tab eventKey="syria" title="رقم سوري">
                    <Syria />
                </Tab>
                <Tab eventKey="turkey" title="رقم تركي">
                    <Turkey />
                </Tab>
            </Tabs>
        </Container>
    );
};

export default TopUp;