import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './TopUp.module.css';
import { Container } from 'react-bootstrap';
import { isValidEmail, isValidMobileNumber } from '../helpers/validators';

const Syria = () => {
    const [listPrices, setListPrices] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [recipientMobileNumber, setRecipientMobileNumber] = useState('');
    const [recipientMobileNumberStatus, setRecipientMobileNumberStatus] = useState('');
    const [mobileNumberSender, setMobileNumberSender] = useState('');
    const [mobileNumberSenderStatus, setMobileNumberSenderStatus] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [email, setEmail] = useState('');
    const [emailStatus, setEmailStatus] = useState('');
    const [isVEmail, setIsVEmail] = useState(false);


    useEffect(() => {

        const authenticated = checkAuthenticationStatus();
        setIsAuthenticated(authenticated);
        const fetchPrices = async () => {
            try {
                const response = await axios.get('https://brmja.co/api/abili-api.php?list-prices');
                setListPrices(response.data);
            } catch (error) {
                console.error('Failed to fetch prices:', error);
            }
        };
        fetchPrices();
    }, []);

    const updateUnits = (direction) => {
        let newIndex = currentIndex + direction;
        if (newIndex >= 0 && newIndex < listPrices.length) {
            setCurrentIndex(newIndex);
        }
    };

    const currentPrice = listPrices[currentIndex] || {};

    const handleMobileNumberSenderChange = (event) => {
        const { value } = event.target;
        setMobileNumberSender(value);
        if (!isValidMobileNumber(value)) {
            // Show an error message if the mobile number is not valid
        } else {
            // Hide the error message if the mobile number is valid
        }
    };

    const handleEmailChange = async (event) => {
        const { value } = event.target;
        setEmail(value);

        if (!isValidEmail(value)) {
            setEmailStatus("الرجاء ادخال بريد الكتروني صحيح");
        } else {
            let evs = "بريد الكتروني غير صحيح";
            try {
                const response = await axios.get(`https://brmja.co/api/validate-email.php?email=${value}`);
                const data = response.data;
                setIsVEmail(data.isVEmail);
                if (data.isVEmail) {
                    evs ="بريد الكتروني صحيح" ;
                    if (data.isFirstTime) {
                        evs = "بريد الكتروني صحيح ولأول مرة معنا :)";
                    }
                }
            } catch (error) {
                console.error('Failed to validate email domain:', error);
            }
            setEmailStatus(evs);
        }
    };

    const handleMobileNumberChange = (event) => {
        const { value } = event.target;
        setRecipientMobileNumber(value);
        updateOperator(value);
        //validateMobileNumber(value);
    };

    const updateOperator = (receiverNum) => {
        // Removing all non-digit characters from the phone number
        let processedNum = receiverNum.replace(/\D/g, '');

        if (processedNum.startsWith("+")) {
            processedNum = processedNum.substring(1);
        }
        if (processedNum.startsWith("00")) {
            processedNum = processedNum.substring(2);
        }
        if (processedNum.startsWith("0")) {
            processedNum = processedNum.substring(1);
        }

        // Checking the country code and removing it
        if (processedNum.startsWith("963")) {
            processedNum = processedNum.substring(3);
        }

        if (/^(99|98|93)/.test(processedNum)) {
            setRecipientMobileNumberStatus("syriatel");

        } else if (/^(94|95|96)/.test(processedNum)) {
            setRecipientMobileNumberStatus("mtn");
        } else if (/^(91|92|97)/.test(processedNum)) {
            setRecipientMobileNumberStatus("لايوجد في سورية ارقام تبدأ بـ 91 92 97");
        } else {
            let numLength = processedNum.length;
            if (numLength === 8) {
                setRecipientMobileNumberStatus("syriatel-code");
            } else if (numLength === 9) {
                setRecipientMobileNumberStatus("mtn-code");
            } else {
                setRecipientMobileNumberStatus("الرجاء ادخال رقم سوري صحيح");
            }
        }
    };
    const checkAuthenticationStatus = () => {
        return false;
    }
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Your code to process the form data
        console.log('Submitted data:', {
            senderMobileNumber: mobileNumberSender,
            senderEmail: email,
            recipientMobileNumber: recipientMobileNumber,
            currentPrice: currentPrice,
            getUserIpLocation: localStorage.getItem('ipLocation'),
        });

        // Call the API or perform any other action with the submitted data
    };
    const validationStatus = () => {
        return {
            isMobileNumberSenderValid: mobileNumberSender !== '' && mobileNumberSenderStatus === '',
            isEmailValid: email !== '' && isVEmail,
            isRecipientMobileNumberValid: recipientMobileNumber !== '',
        };
    };
    const inputValidationStatus = validationStatus();

    return (
        <>
            <div className={`container ${styles['container']}`}>
                {/*<h2>Top Up</h2>*/}
                {/*<form onSubmit={handleSubmit}>*/}
                    <Container className={styles.formGroup}>
                        {!checkAuthenticationStatus() && (
                            <>
                                <input
                                    className={`form-control ${styles['mobile-number']} ${
                                        inputValidationStatus.isMobileNumberSenderValid ? 'is-valid' : 'is-invalid'
                                    }`}
                                    type="tel"
                                    id="sender_num"
                                    name="sender_num"
                                    placeholder="رقم جوالك أنت"
                                    onChange={handleMobileNumberSenderChange}
                                    required
                                />
                                {mobileNumberSenderStatus && <div className="text-danger">{mobileNumberSenderStatus}</div>}
                                <br />
                                <input
                                    className={`form-control ${styles['mobile-number']} ${
                                        inputValidationStatus.isEmailValid ? 'is-valid' : 'is-invalid'
                                    }`}
                                    type="email"
                                    id="sender_email"
                                    name="sender_email"
                                    placeholder="البريد الإلكتروني"
                                    onChange={handleEmailChange}
                                    required
                                />
                                {emailStatus && (
                                    <div className={isVEmail ? "text-success" : "text-danger"}>
                                        {emailStatus}
                                    </div>
                                )}
                            </>
                        )}
                        <input
                            type="tel"
                            id="mobileNumber"
                            className={`form-control ${styles['mobile-number']} ${
                                inputValidationStatus.isRecipientMobileNumberValid ? 'is-valid' : 'is-invalid'
                            }`}
                            value={recipientMobileNumber}
                            placeholder="رقم جوال المستلم"
                            onChange={handleMobileNumberChange}
                            required
                        />
                        {recipientMobileNumberStatus && <div className="text-danger">{recipientMobileNumberStatus}</div>}
                    {/*</Container>*/}
                    {/*<Container className={`${styles.formGroup} d-flex justify-content-center`}>*/}
                        <div className={styles.unitsRow}>

                            <button
                                className="btn btn-success"
                                onClick={() => updateUnits(1)}
                                disabled={currentIndex === listPrices.length - 1}
                            >
                                <i className="fa fa-arrow-up"></i>
                            </button>

                            <input
                                type="text"
                                readOnly
                                className={`form-control ${styles['units-input']}`}
                                value={currentPrice.units || ''}
                            />

                            <button
                                className="btn btn-danger"
                                onClick={() => updateUnits(-1)}
                                disabled={currentIndex === 0}
                            >
                                <i className="fa fa-arrow-down"></i>
                            </button>

                            <p className={`${styles['price']} text-white fw-bold fs-4`}>
                                {currentPrice.price_eu_with_tax || ''} <i className="fa fa-euro-sign"></i>
                                €
                            </p>
                        </div>
                        <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={
                                !(
                                    inputValidationStatus.isMobileNumberSenderValid &&
                                    inputValidationStatus.isEmailValid &&
                                    inputValidationStatus.isRecipientMobileNumberValid
                                )
                            }
                        >
                            Submit
                        </button>
                    </Container>
                {/*</form>*/}
            </div>
        </>
    );
};

export default Syria;
