import React from 'react';
import { Tab, Tabs, Container } from 'react-bootstrap';
import Signup from './Signup';
import Signin from './Signin';

import './../tabs.css';

const Auth = () => {
    return (
        <Container className="auth-container">
            <h2 className="text-center">Authentication</h2>
            <Tabs defaultActiveKey="signin" className="justify-content-center">
                <Tab eventKey="signin" title="Signin">
                    <Signin />
                </Tab>
                <Tab eventKey="signup" title="Signup">
                    <Signup />
                </Tab>
            </Tabs>
        </Container>
    );
};

export default Auth;
