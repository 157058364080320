import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

import "./News.css";
import { Link } from 'react-router-dom';

const News = () => {
    const [news, setNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const fetchNews = async (page) => {
        try {
            const response = await axios.get(`https://brmja.co/news/api.php?page=${page}`);
            console.log('response:', response);

            setNews((prevNews) => {
                const newsSet = new Set(prevNews.map((item) => item.id));
                const newNews = response.data.filter((item) => !newsSet.has(item.id));
                return [...prevNews, ...newNews];
            });
        } catch (error) {
            console.error('Failed to fetch news:', error);
        }
    };

    useEffect(() => {
        fetchNews(currentPage);
    }, [currentPage]);

    const handleLoadMoreClick = () => {
        setCurrentPage(currentPage + 1);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('ar-AE', options);
    };

    return (
        <div>
            <h2>News</h2>
            <div className="row">
                {news.map((newsItem) => (
                    <div key={newsItem.id} className="col-md-4 mb-3">
                        <div className="card news-item">
                            <div className="position-relative">
                                <img
                                    src={`https://brmja.co/news/${newsItem.featured_img}`}
                                    className="card-img-top"
                                    alt={newsItem.title}
                                />
                                <div className="date-overlay">{formatDate(newsItem.date)}</div>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title"
                                    dangerouslySetInnerHTML={{ __html: newsItem.title }}
                                ></h5>
                                <p
                                    className="card-text"
                                    dangerouslySetInnerHTML={{ __html: newsItem.excerpt }}
                                ></p>
                                <Link to={`/news/${newsItem.id}`} className="btn btn-primary float-left">
                                    المقال كاملاً..
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="text-center">
                <button id="load-more" className="btn btn-primary mt-3" onClick={handleLoadMoreClick}>عرض المزيد</button>
            </div>
        </div>
    );
};

export default News;
