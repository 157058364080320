export const isValidEmail = (email) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
};

export const isValidMobileNumber = (number) => {
    const mobileNumberRegex = /^(\+963|00963|0)?[1-9]\d{7,8}$/;
    return mobileNumberRegex.test(number);
};

