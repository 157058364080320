import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';
const Signin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://example.com/users.php?action=signin', {
                email,
                password,
            });

            if (response.data.success) {
                // Save the user data (e.g., token) and redirect to the desired page
            } else {
                // Show an error message
            }
        } catch (error) {
            console.error('Error during signin:', error);
        }
    };

    return (
        <div>
            <h3 className="text-center">Signin</h3>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100">
                    Signin
                </Button>
            </Form>
        </div>
    );
};

export default Signin;
